<template>
  <TestComponent 
    testTitle="JLPT N5 Grammar Test" 
    jsonFile="/jlpt/n5/grammar/jlpt_n5_grammar_questions.json"
  />
</template>

<script>
import TestComponent from '@/components/jlpt/TestComponent.vue';

export default {
  name: 'JLPTN5GRAMMARTESTCOMPONENT',
  components: {
    TestComponent
  },
  metaInfo() {
    return {
      title: 'JLPT N5 Grammar Practice Test | Gyanmirai',
      meta: [
        {
          name: 'description',
          content: 'Take the JLPT N5 Grammar practice test on Gyanmirai. Test your understanding of essential N5 grammar points and prepare for the JLPT exam with our comprehensive mock tests.'
        },
        {
          name: 'keywords',
          content: 'JLPT N5, Grammar test, JLPT N5 grammar, JLPT mock tests, Japanese grammar, N5 exam preparation, Gyanmirai'
        },
        {
          name: 'robots',
          content: 'index, follow'
        },
        {
          property: 'og:title',
          content: 'JLPT N5 Grammar Practice Test | Gyanmirai'
        },
        {
          property: 'og:description',
          content: 'Prepare for the JLPT N5 exam by practicing grammar with Gyanmirai’s free mock tests. Test your N5 grammar knowledge and improve your chances of success in the exam.'
        },
        {
          property: 'og:url',
          content: 'https://www.gyanmirai.com/jlpt/practice-test/n5/grammar'
        },
        {
          property: 'og:type',
          content: 'website'
        },
        {
          property: 'og:image',
          content: 'https://www.gyanmirai.com/images/banner.jpg'  // Replace with a relevant banner image for grammar
        }
      ],
      link: [
        { rel: 'canonical', href: 'https://www.gyanmirai.com/jlpt/practice-test/n5/grammar' }
      ]
    }
  }
}
</script>
