<template>
  <div class="home">
    <NavbarComponent />
    <BannerComponent />
    <section class="info-section">
      <InquiryFormComponent class="half-width" />
      <CoursesComponent class="half-width" />
    </section>
    <section class="mission-section">
      <MissionComponent />
    </section>
    <section class="chooseus-section">
      <WhyChooseUsComponent />
    </section>
  </div>
</template>

<script>
import BannerComponent from '@/components/home/BannerComponent.vue'
import InquiryFormComponent from '@/components/InquiryFormComponent.vue'
import CoursesComponent from '@/components/home/CoursesComponent.vue'
import MissionComponent from '@/components/home/MissionComponent.vue'
import WhyChooseUsComponent from '@/components/home/WhyChooseUsComponent.vue'

export default {
  name: 'HomeView',
  components: {
    BannerComponent,
    InquiryFormComponent,
    CoursesComponent,
    MissionComponent,
    WhyChooseUsComponent
  },
  metaInfo() {
    return {
      title: 'Gyanmirai | Japanese Language Classes, JLPT Mock Tests, and Career Guidance',
      meta: [
        {
          name: 'description',
          content: 'Gyanmirai offers Japanese language classes, JLPT and NAT mock tests for kanji, vocabulary, grammar, listening, and reading. Get expert career guidance to help you secure a job in Japan.'
        },
        {
          name: 'keywords',
          content: 'Japanese language classes, JLPT mock tests, NAT test preparation, kanji practice, grammar lessons, listening skills, reading comprehension, career guidance, jobs in Japan, Gyanmirai'
        },
        {
          name: 'robots',
          content: 'index, follow'
        },
        {
          property: 'og:title',
          content: 'Gyanmirai: Japanese Language Classes, JLPT Mock Tests, and Career Guidance'
        },
        {
          property: 'og:description',
          content: 'Join Gyanmirai for expert Japanese language instruction, JLPT & NAT mock tests for kanji, vocabulary, grammar, listening, and reading, plus career guidance to help you secure a job in Japan.'
        },
        {
          property: 'og:url',
          content: 'https://www.gyanmirai.com'
        },
        {
          property: 'og:type',
          content: 'website'
        },
        {
          property: 'og:image',
          content: 'https://www.gyanmirai.com/images/banner.jpg'  // Add a link to your relevant banner image
        }
      ],
      link: [
        { rel: 'canonical', href: 'https://www.gyanmirai.com/' }
      ]
    }
  }
}
</script>

<style scoped>
.info-section {
  display: flex;
  justify-content: space-between;
  background: #f5f5f5;
  border-radius: 15px;
  margin: 10px;
}

.mission-section {
  display: flex;
  justify-content: space-between;
  background: #f5f5f5;
  border-radius: 15px;
  margin: 10px;
}

.chooseus-section {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  background: #f5f5f5;
  border-radius: 15px;
  margin: 10px;
}

.half-width {
  flex: 1;
  margin: 10px;
  background: white;
  border-radius: 15px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.block {
  background: white;
  padding: 30px;
  margin-bottom: 20px;
  border-radius: 15px; /* Rounded corners to match the navbar */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  max-width: 2000px; /* Set maximum width to match the navbar */
  width: 100%; /* Ensure it takes the full width of its container */
  margin: 0 auto 20px auto; /* Center the block */
}

h2 {
  font-size: 2.5em;
  margin-bottom: 20px;
}

p, ul {
  font-size: 1.2em;
  line-height: 1.6;
  margin-bottom: 20px;
}

ul {
  list-style-type: disc;
  margin-left: 20px;
}

ul li {
  margin-bottom: 10px;
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  /* Stack the items vertically */
  .info-section, .mission-section {
    flex-direction: column;
    padding: 10px;
  }

  /* Make each component take full width */
  .half-width {
    width: 100%;
    padding: 20px 0;
    margin: 0, 10px;
  }

  .block {
    padding: 20px;
  }

  h2 {
    font-size: 2em;
  }

  p, ul {
    font-size: 1em;
  }
}
</style>
