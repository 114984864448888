<template>
    <section class="block">
      <h2>About Our Company</h2>
      <p>Our company was established with the vision of bridging the gap between World and Japan through language education. We are committed to providing high-quality instruction and support to our students, helping them succeed in their JLPT exams and pursue their dreams in Japan.</p>
    </section>
  </template>
  
  <script>
  export default {
    name: 'AboutCompanyComponent'
  };
  </script>
  
  <style scoped>
  .block {
    background: white;
    padding: 30px;
    margin-bottom: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s, box-shadow 0.3s;
  }
  
  .block:hover {
    transform: translateY(-5px);
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  }
  
  h2 {
    font-size: 2em;
    margin-bottom: 20px;
    color: #333; /* Darker color for better contrast */
  }
  
  p {
    font-size: 1.2em;
    line-height: 1.6;
    margin-bottom: 20px;
    color: #666; /* Slightly lighter color for better readability */
  }
  </style>
  