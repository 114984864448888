<template>
    <KanjiComponent 
      title="JLPT N1 Kanji List" 
      jsonFile="/jlpt/n1/kanji/jlpt_n1_kanji_list.json"
    />
  </template>
  
  <script>
  import KanjiComponent from '@/components/jlpt/KanjiComponent.vue';
  
  export default {
    name: 'JLPTN1KANJILIST',
    components: {
      KanjiComponent
    },
    head() {
      return {
        title: 'JLPT N1 Kanji List - Learn Essential Kanji for JLPT N1',
        meta: [
          { 
            name: 'description', 
            content: 'Comprehensive list of JLPT N1 Kanji with their meanings, readings, and examples. Start your journey to master Japanese Kanji with our easy-to-follow guide.' 
          },
          { 
            name: 'keywords', 
            content: 'JLPT N1, Kanji List, Japanese Kanji, JLPT preparation, Learn Japanese, N1 Kanji meanings, N1 Kanji readings' 
          },
          { 
            name: 'robots', 
            content: 'index, follow' 
          },
          { 
            property: 'og:title', 
            content: 'JLPT N1 Kanji List - Master Essential Kanji' 
          },
          { 
            property: 'og:description', 
            content: 'Explore the JLPT N1 Kanji list, complete with their meanings, readings, and usage examples to boost your Japanese language skills.' 
          },
          { 
            property: 'og:image', 
            content: 'https://www.gyanmirai.com/images/banner.jpg' 
          },
          { 
            property: 'og:url', 
            content: 'https://www.gyanmirai.com/jlpt/study/n1/kanji' 
          },
          { 
            property: 'og:site_name', 
            content: 'Gyanmirai' 
          },
        ],
        link: [
        { rel: 'canonical', href: 'https://www.gyanmirai.com/jlpt/study/n1/kanji' }
        ]
      };
    }
  };
  </script>
  