import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import VueGtag from 'vue-gtag'

const app = createApp(App)

app.use(VueGtag, {
  config: { id: "G-8WWVBLMD3Y" },  // Replace G-XXXXXXXXXX with your actual Measurement ID
  enabled: process.env.NODE_ENV === 'production'
}, router)

app.use(router)
app.mount('#app')
