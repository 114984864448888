<template>
  <div class="block">
    <h2>Why Choose Us?</h2>
    <ul>
      <li>Experienced Instructors</li>
      <li>Personalized Study Plans</li>
      <li>Proven Success Rates</li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'WhyChoseUsComponent'
};
</script>

<style scoped>
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css');

.block {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #f7f9fc;
  padding: 10px 30px;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  margin: 20px;
}

.block h2 {
  font-size: 2.5em;
  margin-bottom: 30px;
  color: #007BFF;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
}

.block ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  text-align: left; 
  display: inline-block; 
}

.block ul li {
  margin-bottom: 20px;
  font-size: 1.5em;
  color: #555;
  position: relative;
  padding-left: 25px;
}

.block ul li::before {
  content: '\f00c';
  font-family: 'FontAwesome';
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  color: #007BFF;
}

@media (max-width: 768px) {
  .block {
    padding: 10px;
  }

  .block h2 {
    font-size: 2em;
    margin-bottom: 20px;
  }

  .block ul {
    width: 100%;
  }

  .block ul li {
    font-size: 1em;
    margin-bottom: 15px;
    padding-left: 20px;
  }

  .block ul li::before {
    font-size: 0.9em;
  }
}
</style>
