<template>
  <div class="contact">
    <NavbarComponent />
    <div class="container">
      <section class="block">
        <InquiryFormComponent />
      </section>
    </div>
    <FooterComponent />
  </div>
</template>

<script>
import InquiryFormComponent from '@/components/InquiryFormComponent.vue';

export default {
  name: 'ContactView',
  components: {
    InquiryFormComponent
  },
  metaInfo() {
    return {
      title: 'Contact Us | Gyanmirai',
      meta: [
        {
          name: 'description',
          content: 'Get in touch with Gyanmirai for any inquiries or support regarding our Japanese language courses and JLPT preparation. Fill out our contact form for prompt assistance.'
        },
        {
          name: 'keywords',
          content: 'Contact us, Japanese language support, JLPT preparation, Gyanmirai, inquiry form, Japanese language courses'
        },
        {
          name: 'robots',
          content: 'index, follow'
        },
        {
          property: 'og:title',
          content: 'Contact Us | Gyanmirai'
        },
        {
          property: 'og:description',
          content: 'Have questions or need support? Contact Gyanmirai for help with our Japanese language courses and JLPT preparation. Fill out the form for a quick response.'
        },
        {
          property: 'og:url',
          content: 'https://www.gyanmirai.com/contact'
        },
        {
          property: 'og:type',
          content: 'website'
        },
        {
          property: 'og:image',
          content: 'https://www.gyanmirai.com/images/banner.jpg'  // Replace with a relevant banner image
        }
      ],
      link: [
        { rel: 'canonical', href: 'https://www.gyanmirai.com/contact' }
      ]
    }
  }
}
</script>

<style scoped>
.container {
  padding: 50px 20px;
  text-align: center;
  max-width: 800px;
  margin: 0 auto;
}

.block {
  background: white;
  padding: 30px;
  margin-bottom: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

h2 {
  font-size: 2em;
  margin-bottom: 20px;
}
</style>
