// testLogic.js

import { ref, computed } from 'vue';

export function useTestLogic() {
  const questions = ref([]);
  const selectedQuestions = ref([]);
  const currentQuestionIndex = ref(0);
  const userAnswers = ref([]);
  const testStarted = ref(false);
  const testCompleted = ref(false);
  const questionCount = ref(5);
  const maxQuestions = ref(0);
  const startTime = ref(null);
  const timeElapsed = ref(0);
  const timerInterval = ref(null);

  const currentQuestion = computed(() => selectedQuestions.value[currentQuestionIndex.value]);
  const score = computed(() => {
    return selectedQuestions.value.reduce((acc, question, index) => {
      return acc + (userAnswers.value[index] === question.correct_answer ? 1 : 0);
    }, 0);
  });

  const loadQuestions = async (jsonFile) => {
    try {
      const response = await fetch(jsonFile);
      questions.value = await response.json();
      maxQuestions.value = questions.value.length;
    } catch (error) {
      console.error("Error loading questions:", error);
    }
  };

  const startTest = () => {
    selectedQuestions.value = getRandomQuestions(questionCount.value);
    userAnswers.value = new Array(selectedQuestions.value.length).fill(null);
    testStarted.value = true;
    startTime.value = Date.now();
    startTimer();
  };

  const getRandomQuestions = (count) => {
    let shuffled = [...questions.value].sort(() => 0.5 - Math.random());
    return shuffled.slice(0, count);
  };

  const selectAnswer = (answer) => {
    userAnswers.value[currentQuestionIndex.value] = answer;
  };

  const nextQuestion = () => {
    if (currentQuestionIndex.value < selectedQuestions.value.length - 1) {
      currentQuestionIndex.value++;
    }
  };

  const previousQuestion = () => {
    if (currentQuestionIndex.value > 0) {
      currentQuestionIndex.value--;
    }
  };

  const goToQuestion = (index) => {
    currentQuestionIndex.value = index;
  };

  const finishTest = () => {
    testCompleted.value = true;
    stopTimer();
  };

  const resetTest = () => {
    testStarted.value = false;
    testCompleted.value = false;
    currentQuestionIndex.value = 0;
    userAnswers.value = [];
    timeElapsed.value = 0;
    startTime.value = null;
  };

  const startTimer = () => {
    timerInterval.value = setInterval(() => {
      timeElapsed.value = Math.floor((Date.now() - startTime.value) / 1000);
    }, 1000);
  };

  const stopTimer = () => {
    clearInterval(timerInterval.value);
  };

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
  };

  const formatQuestion = (question) => {
    return question.replace(/<b>/g, '<span style="font-weight: bold;">').replace(/<\/b>/g, '</span>');
  };

  return {
    questions,
    selectedQuestions,
    currentQuestionIndex,
    userAnswers,
    testStarted,
    testCompleted,
    questionCount,
    maxQuestions,
    timeElapsed,
    currentQuestion,
    score,
    stopTimer,
    loadQuestions,
    startTest,
    selectAnswer,
    nextQuestion,
    previousQuestion,
    goToQuestion,
    finishTest,
    resetTest,
    formatTime,
    formatQuestion
  };
}