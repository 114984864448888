<template>
  <TestComponent 
    testTitle="JLPT N4 Kanji Test" 
    jsonFile="/jlpt/n4/kanji/jlpt_n4_kanji_questions.json"
  />
</template>

<script>
import TestComponent from '@/components/jlpt/TestComponent.vue';

export default {
  name: 'JLPTN4KANJITESTCOMPONENT',
  components: {
    TestComponent
  },
  metaInfo() {
    return {
      title: 'JLPT N4 Kanji Practice Test | Gyanmirai',
      meta: [
        {
          name: 'description',
          content: 'Take the JLPT N4 Kanji practice test on Gyanmirai. Test your knowledge of the essential N4 kanji and prepare for the JLPT exam with our comprehensive mock tests.'
        },
        {
          name: 'keywords',
          content: 'JLPT N4, Kanji test, JLPT N4 kanji, JLPT mock tests, Japanese kanji, N4 exam preparation, Gyanmirai'
        },
        {
          name: 'robots',
          content: 'index, follow'
        },
        {
          property: 'og:title',
          content: 'JLPT N4 Kanji Practice Test | Gyanmirai'
        },
        {
          property: 'og:description',
          content: 'Prepare for the JLPT N4 exam by practicing kanji with Gyanmirai’s free mock tests. Test your N4 kanji knowledge and improve your chances of success in the exam.'
        },
        {
          property: 'og:url',
          content: 'https://www.gyanmirai.com/jlpt/practice-test/n4/kanji'
        },
        {
          property: 'og:type',
          content: 'website'
        },
        {
          property: 'og:image',
          content: 'https://www.gyanmirai.com/images/banner.jpg'  // Replace with a relevant banner image
        }
      ],
      link: [
        { rel: 'canonical', href: 'https://www.gyanmirai.com/jlpt/practice-test/n4/kanji' }
      ]
    }
  }
}
</script>
