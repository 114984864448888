<template>
    <div class="kanji-view">
      <h1>{{ title }}</h1>
      <div class="search-bar">
        <input v-model="searchQuery" placeholder="Search kanji, meaning, or reading" @input="filterKanji">
      </div>
  
      <div class="kanji-grid">
        <div
          class="kanji-card"
          v-for="(kanjiItem, index) in filteredKanjiList"
          :key="index"
          @click="openKanjiDetails(index)"
        >
          <div class="kanji-character">
            <h2>{{ kanjiItem.kanji }}</h2>
          </div>
          <div class="kanji-info">
            <p><strong>Meaning:</strong> {{ kanjiItem['meaning'] }}</p>
          </div>
        </div>
      </div>
  
      <!-- Modal for Kanji details -->
      <div v-if="activeKanjiIndex !== null" class="kanji-modal">
        <div class="kanji-modal-content">
          <h2 class="kanji-modal-header">{{ filteredKanjiList[activeKanjiIndex].kanji }}</h2>
          <div class="kanji-details">
            <p><strong>On-yomi:</strong> {{ filteredKanjiList[activeKanjiIndex]['on-yomi'] }}</p>
            <p><strong>Kun-yomi:</strong> {{ filteredKanjiList[activeKanjiIndex]['kun-yomi'] }}</p>
            <p><strong>Meaning:</strong> {{ filteredKanjiList[activeKanjiIndex].meaning }}</p>
          </div>
  
          <div class="examples">
            <h3>Examples:</h3>
            <ul>
                <li
                    v-for="(example, exIndex) in parseExamples(filteredKanjiList[activeKanjiIndex].examples)"
                    :key="exIndex"
                >
                    <span class="japanese">{{ example.japanese }}</span>
                    <span class="reading">[{{ example.reading }}]</span>
                    <span class="translation">{{ example.translation }}</span>
                </li>
            </ul>

          </div>
  
          <button class="learned-btn" @click="markAsLearned(activeKanjiIndex)">
            {{ filteredKanjiList[activeKanjiIndex].learned ? 'Unmark as Learned' : 'Mark as Learned' }}
          </button>
  
          <div class="navigation-buttons">
            <button class="nav-btn" @click="navigateKanji(-1)">Previous</button>
            <button class="nav-btn" @click="navigateKanji(1)">Next</button>
          </div>
  
          <button class="close-btn" @click="closeKanjiDetails">Close</button>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { onMounted } from 'vue';
  import { useKanjiLogic } from './KanjiLogic';

  console.log(useKanjiLogic);
  
  export default {
    name: 'KanjiComponent',
    props: {
      title: {
        type: String,
        required: true
      },
      jsonFile: {
        type: String,
        required: true
      }
    },
    setup(props) {
      const KanjiLogic = useKanjiLogic();
  
      onMounted(() => {
        KanjiLogic.fetchKanjiData(props.jsonFile);
      });
  
      return {
        ...KanjiLogic
      };
    }
  };
  </script>
  
  <style scoped>
  .kanji-view {
    font-family: 'Noto Sans JP', Arial, sans-serif;
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
    text-align: center;
  }
  
  .search-bar input {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-bottom: 20px;
  }
  
  .kanji-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 20px;
  }
  
  .kanji-card {
    background-color: #f9f9f9;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    padding: 10px;
    transition: all 0.3s ease;
    cursor: pointer;
    position: relative;
    text-align: center;
  }
  
  .kanji-card:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .kanji-character {
    font-size: 60px;
    margin-bottom: 10px;
  }
  
  .kanji-info {
    font-size: 14px;
    color: #555;
  }
  
  .kanji-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .kanji-modal-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    width: 500px;
    text-align: left;
  }

  .kanji-modal-header {
    text-align: center;
    margin-bottom: 15px;
    font-size: 2rem;
    font-weight: bold;
  }
  
  .kanji-details p {
    font-size: 1.1rem;
    margin: 10px 0;
  }

  .examples {
    margin-top: 20px;
  }

  .examples h3 {
    font-size: 1.2rem;
    margin-bottom: 10px;
  }
  
  .examples ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  
  .examples li {
    margin-bottom: 10px;
  }
  
  .japanese {
    font-weight: bold;
  }
  
  .reading {
    margin-left: 10px;
    color: #555;
  }
  
  .translation {
    margin-left: 10px;
    color: #333;
  }
  
  .navigation-buttons {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
  }

  .nav-btn {
    padding: 10px 20px;
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    border-radius: 5px;
    cursor: pointer;
  }

  .nav-btn:hover {
    background-color: #e0e0e0;
  }
  
  .learned-btn {
    background-color: #4caf50;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 20px;
    width: 100%;
  }
  
  .learned-btn:hover {
    background-color: #45a049;
  }
  
  .close-btn {
    background-color: #ff5722;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 20px;
    width: 100%;
  }
  
  .close-btn:hover {
    background-color: #e64a19;
  }
  </style>