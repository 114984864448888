<template>
  <div class="info-block">
    <h2>Our Courses</h2>
    <transition name="fade" mode="out-in">
      <div class="course" :key="currentCourseIndex">
        <h3>{{ currentCourse.level }}</h3>
        <ul>
          <li><i class="fas fa-book"></i> <span class="bold">Grammar:</span> {{ currentCourse.grammar }}</li>
          <li><i class="fas fa-pen"></i> <span class="bold">Vocabulary:</span> {{ currentCourse.vocabulary }}</li>
          <li><i class="fas fa-font"></i> <span class="bold">Kanji:</span> {{ currentCourse.kanji }}</li>
        </ul>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'CoursesComponent',
  data() {
    return {
      courses: [
        {
          level: 'JLPT N5',
          grammar: 'Basic sentence structures.',
          vocabulary: 'Essential everyday words and phrases.',
          kanji: 'Approximately 100 basic kanji characters.'
        },
        {
          level: 'JLPT N4',
          grammar: 'More complex sentence structures.',
          vocabulary: 'Broader range of everyday and intermediate words.',
          kanji: 'Approximately 300 additional kanji characters.'
        },
        {
          level: 'JLPT N3',
          grammar: 'Advanced sentence structures.',
          vocabulary: 'Extensive vocabulary for everyday and professional use.',
          kanji: 'Approximately 600 additional kanji characters.'
        }
      ],
      currentCourseIndex: 0
    };
  },
  computed: {
    currentCourse() {
      return this.courses[this.currentCourseIndex];
    }
  },
  mounted() {
    this.startCourseRotation();
  },
  methods: {
    startCourseRotation() {
      setInterval(() => {
        this.currentCourseIndex = (this.currentCourseIndex + 1) % this.courses.length;
      }, 5000);
    }
  }
};
</script>

<style scoped>
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css');

.info-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #f7f9fc;
  padding: 30px;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.info-block h2 {
  font-size: 2.5em;
  margin-bottom: 30px;
  color: #007BFF;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
}

.course {
  width: 90%;
  max-width: 600px;
  margin: 20px 0;
  padding: 25px;
  background: white;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  position: relative;
  overflow: hidden;
  transition: transform 0.3s, box-shadow 0.3s;
}

.course:before {
  content: '';
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: radial-gradient(circle, rgba(255, 255, 255, 0.2), rgba(0, 123, 255, 0.1));
  transform: rotate(45deg);
  transition: opacity 0.3s;
}

.course:hover {
  transform: translateY(-10px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
}

.course:hover:before {
  opacity: 0.5;
}

.course h3 {
  font-size: 1.8em;
  margin-bottom: 20px;
  color: #333;
  font-weight: bold;
}

.course ul {
  list-style-type: none;
  padding: 0;
  text-align: left;
}

.course li {
  margin: 30px 0; /* Increased margin for better spacing */
  font-size: 1.1em;
  display: flex;
  align-items: center;
  color: #555;
}

.course li i {
  margin-right: 10px;
  color: #007BFF;
  font-size: 1.2em;
}

.bold {
  font-weight: bold;
  margin-right: 5px;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
@media (max-width: 768px) {
  .info-block {
    padding: 15px;
  }

  .info-block h2 {
    font-size: 1.8em;
    margin-bottom: 15px;
  }

  .course {
    padding: 15px;
  }

  .course h3 {
    font-size: 1.3em;
  }

  .course li {
    font-size: 0.9em;
    margin: 10px 0;
  }

  .course li i {
    font-size: 1em;
  }
}
</style>
