<template>
  <div class="jlpt-view">
    <div class="title-section">
      <h1 class="title">JAPANESE LANGUAGE</h1>
      <p class="subtitle">Master Japanese, One Level at a Time</p>
      <div class="title-decoration">
        <span class="japanese-char">日</span>
        <span class="japanese-char">本</span>
        <span class="japanese-char">語</span>
      </div>
    </div>

    <div class="level-selector">
      <button 
        v-for="level in jlptLevels" 
        :key="level"
        @click="selectedLevel = level"
        :class="['level-button', { active: selectedLevel === level }]"
      >
        {{ level }}
      </button>
    </div>

    <transition name="fade">
      <div class="content-grid" v-if="selectedLevel">
        <div class="level-group">
          <h2 class="level-title">{{ selectedLevel }} Tests</h2>
          <div class="link-grid">
            <router-link 
              v-for="test in tests" 
              :key="test.name"
              :to="`/jlpt/practice-test/${selectedLevel.toLowerCase()}/${test.name.toLowerCase()}`" 
              class="content-link"
            >
              <span class="content-icon" :class="test.name.toLowerCase()"></span>
              {{ test.name }} Test
            </router-link>
          </div>
        </div>

        <div class="level-group">
          <h2 class="level-title">{{ selectedLevel }} Study</h2>
          <div class="link-grid">
            <router-link 
              :to="`/jlpt/study/${selectedLevel.toLowerCase()}/kanji`" 
              class="content-link"
            >
              <span class="content-icon kanji"></span>
              Kanji List
            </router-link>
          </div>
        </div>
      </div>
    </transition>

    <div v-if="!selectedLevel" class="no-selection">
      Select your JLPT level above to begin your practice journey
    </div>
  </div>
</template>

<script>
export default {
  name: 'JLPTView',
  data() {
    return {
      jlptLevels: ['N5', 'N4', 'N3', 'N2', 'N1'],
      tests: [
        { name: 'Kanji' },
        { name: 'Grammar' }
      ],
      selectedLevel: null
    }
  },
  metaInfo() {
    return {
      title: 'JLPT Practice Tests | Gyanmirai',
      meta: [
        { name: 'description', content: 'Access JLPT practice tests for N5 to N1 on Gyanmirai. Enhance your Japanese language skills with targeted Kanji and Grammar tests.' },
        { name: 'keywords', content: 'JLPT, practice tests, kanji, grammar, N5, N4, N3, N2, N1, Japanese language' },
        { name: 'robots', content: 'index, follow' },
        { property: 'og:title', content: 'JLPT Practice Tests | Gyanmirai' },
        { property: 'og:description', content: 'Prepare for the JLPT exam with comprehensive Kanji and Grammar practice tests at all levels.' },
        { property: 'og:url', content: 'https://www.gyanmirai.com/jlpt' },
        { property: 'og:type', content: 'website' },
        { property: 'og:image', content: 'https://www.gyanmirai.com/images/banner.jpg' }
      ],
      link: [
        { rel: 'canonical', href: 'https://www.gyanmirai.com/jlpt' }
      ]
    }
  }
}
</script>

<style scoped>
.jlpt-view {
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
  font-family: 'Arial', sans-serif;
  background-color: #f0f4f8;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.title-section {
  text-align: center;
  padding: 30px 0;
  background: linear-gradient(to right, #6a11cb, #2575fc);
  border-radius: 10px 10px 0 0;
  color: white;
  position: relative;
  overflow: hidden;
}

.title {
  font-size: 2.8em;
  margin-bottom: 10px;
  font-weight: bold;
  text-shadow: 2px 2px 4px rgba(0,0,0,0.3);
}

.subtitle {
  font-size: 1.4em;
  margin-bottom: 20px;
  font-style: italic;
}

.title-decoration {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0.1;
  font-size: 8em;
  font-weight: bold;
  display: flex;
  justify-content: center;
  width: 100%;
}

.japanese-char {
  margin: 0 10px;
  animation: float 3s ease-in-out infinite;
}

.japanese-char:nth-child(2) {
  animation-delay: 0.5s;
}

.japanese-char:nth-child(3) {
  animation-delay: 1s;
}

@keyframes float {
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-10px); }
}

.level-selector {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin: 30px 0;
  flex-wrap: wrap;
}

.level-button {
  padding: 12px 24px;
  border: none;
  background: linear-gradient(to right, #6a11cb, #2575fc);
  color: white;
  font-size: 1.1em;
  cursor: pointer;
  transition: all 0.3s ease;
  border-radius: 5px;
  font-weight: bold;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.level-button.active {
  background: linear-gradient(to right, #5a0cb1, #1e65e0);
  transform: scale(1.05);
}

.level-button:hover {
  background: linear-gradient(to right, #5a0cb1, #1e65e0);
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0,0,0,0.2);
}

.level-group {
  background-color: white;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.level-title {
  font-size: 1.8em;
  margin-bottom: 20px;
  color: #2c3e50;
  text-align: center;
}

.test-links {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
}

.test-link {
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 15px;
  border-radius: 5px;
  background-color: #ecf0f1;
  color: #2c3e50;
  font-weight: bold;
  transition: all 0.3s ease;
}

.test-link:hover {
  background-color: #3498db;
  color: white;
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.test-icon {
  width: 32px;
  height: 32px;
  margin-right: 10px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.kanji {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%233498db"><path d="M5 3v2h2V3H5zm4 0v2h2V3H9zm4 0v2h2V3h-2zm4 0v2h2V3h-2zM3 7v2h2V7H3zm4 0v2h10V7H7zm12 0v2h2V7h-2zM3 11v2h2v-2H3zm4 0v2h10v-2H7zm12 0v2h2v-2h-2zM3 15v2h2v-2H3zm4 0v2h10v-2H7zm12 0v2h2v-2h-2zM5 19v2h2v-2H5zm4 0v2h2v-2H9zm4 0v2h2v-2h-2zm4 0v2h2v-2h-2z"/></svg>');
}

.grammar {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%233498db"><path d="M3 3v18h18V3H3zm16 16H5V5h14v14zM7 7h10v2H7V7zm0 4h10v2H7v-2zm0 4h7v2H7v-2z"/></svg>');
}

.no-selection {
  text-align: center;
  color: #7f8c8d;
  font-style: italic;
  margin-top: 20px;
  padding: 20px;
  background-color: #ecf0f1;
  border-radius: 5px;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
.content-grid {
  display: grid;
  gap: 30px;
}

.level-group {
  background-color: white;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.level-title {
  font-size: 1.8em;
  margin-bottom: 20px;
  color: #2c3e50;
  text-align: center;
}

.link-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
}

.content-link {
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 15px;
  border-radius: 5px;
  background-color: #ecf0f1;
  color: #2c3e50;
  font-weight: bold;
  transition: all 0.3s ease;
}

.content-link:hover {
  background-color: #3498db;
  color: white;
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.content-icon {
  width: 32px;
  height: 32px;
  margin-right: 10px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

@media (max-width: 600px) {
  .title {
    font-size: 2.2em;
  }

  .subtitle {
    font-size: 1.2em;
  }

  .title-decoration {
    font-size: 6em;
  }

  .level-button {
    padding: 10px 20px;
    font-size: 1em;
  }

  .test-links {
    grid-template-columns: 1fr;
  }
}
</style>