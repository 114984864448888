<template>
  <nav class="navbar">
    <router-link to="/" class="logo">
      <img src="@/assets/logo.png" alt="Logo">
    </router-link>
    <ul class="nav-links">
      <li><router-link to="/">Home</router-link></li>
      <li><router-link to="/about">About Us</router-link></li>
      <li><router-link to="/contact">Contact</router-link></li>
      <li><router-link to="/jlpt">JLPT</router-link></li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: 'NavbarComponent'
}
</script>

<style scoped>
body {
  margin: 0;
  font-family: Arial, Helvetica, sans-serif;
}

.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 30px;
  background: linear-gradient(to right, #6a11cb, #2575fc);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  height: 50px;
  border-radius: 15px;
  margin: 20px;
  position: sticky;
  top: 0;
  z-index: 1000;
}

.logo img {
  width: 50px;
}

.nav-links {
  list-style: none;
  display: flex;
  justify-content: center;
  flex: 1;
  margin: 0;
  padding: 0;
}

.nav-links li {
  margin: 0 20px; /* Reduced space between links for mobile */
  transition: transform 0.3s ease-in-out, color 0.3s ease-in-out;
}

.nav-links a {
  color: white;
  text-decoration: none;
  font-weight: bold;
  font-size: 1em; /* Adjusted font size for mobile */
  transition: transform 0.3s ease-in-out, color 0.3s ease-in-out;
  position: relative;
}

@media (max-width: 768px) {
  .logo {
    margin: 0; /* Remove margin for logo on mobile */
  }

  .navbar {
    padding: 10px; /* Adjusted padding for mobile */
    height: auto; /* Allow navbar height to adjust based on content */
  }

  .nav-links li {
    margin: 10px; /* Adjusted margin for mobile */
  }

  .nav-links a {
    font-size: 1em; /* Revert font size back for desktop */
  }
}
</style>