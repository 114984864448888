import { createRouter, createWebHistory } from 'vue-router';
import HomeView from '@/views/HomeView.vue';
import AboutView from '@/views/AboutView.vue';
import ContactView from '@/views/ContactView.vue';
import JlptView from '@/views/JlptView.vue';
import JLPTN5KANJITESTCOMPONENT from '@/components/jlpt/n5/practice_test/Jlpt_n5_kanji_test.vue';
import JLPTN5GRAMMARTESTCOMPONENT from '@/components/jlpt/n5/practice_test/Jlpt_n5_grammar_test.vue';
import JLPTN5KANJILIST from '@/components/jlpt/n5/study/jlpt_n5_kanji_list.vue';
import JLPTN4KANJITESTCOMPONENT from '@/components/jlpt/n4/practice_test/Jlpt_n4_kanji_test.vue';
import JLPTN4GRAMMARTESTCOMPONENT from '@/components/jlpt/n4/practice_test/Jlpt_n4_grammar_test.vue';
import JLPTN4KANJILIST from '@/components/jlpt/n4/study/jlpt_n4_kanji_list.vue';
import JLPTN3KANJITESTCOMPONENT from '@/components/jlpt/n3/practice_test/Jlpt_n3_kanji_test.vue';
import JLPTN3GRAMMARTESTCOMPONENT from '@/components/jlpt/n3/practice_test/Jlpt_n3_grammar_test.vue';
import JLPTN3KANJILIST from '@/components/jlpt/n3/study/jlpt_n3_kanji_list.vue';
import JLPTN2KANJITESTCOMPONENT from '@/components/jlpt/n2/practice_test/Jlpt_n2_kanji_test.vue';
import JLPTN2GRAMMARTESTCOMPONENT from '@/components/jlpt/n2/practice_test/Jlpt_n2_grammar_test.vue';
import JLPTN2KANJILIST from '@/components/jlpt/n2/study/jlpt_n2_kanji_list.vue';
import JLPTN1KANJITESTCOMPONENT from '@/components/jlpt/n1/practice_test/Jlpt_n1_kanji_test.vue';
import JLPTN1GRAMMARTESTCOMPONENT from '@/components/jlpt/n1/practice_test/Jlpt_n1_grammar_test.vue';
import JLPTN1KANJILIST from '@/components/jlpt/n1/study/jlpt_n1_kanji_list.vue';


const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'About',
    component: AboutView
  },
  {
    path: '/contact',
    name: 'Contact',
    component: ContactView
  },
  {
    path: '/jlpt',
    name: 'JLPT',
    component: JlptView
  },
  {
    path: '/jlpt/practice-test/n5/kanji',
    name: 'N5KanjiTest',
    component: JLPTN5KANJITESTCOMPONENT
  },
  {
    path: '/jlpt/practice-test/n5/grammar',
    name: 'N5GrammarTest',
    component: JLPTN5GRAMMARTESTCOMPONENT
  },
  {
    path: '/jlpt/study/n5/kanji',
    name: 'N5KanjiList',
    component: JLPTN5KANJILIST
  },
  {
    path: '/jlpt/practice-test/n4/kanji',
    name: 'N4KanjiTest',
    component: JLPTN4KANJITESTCOMPONENT
  },
  {
    path: '/jlpt/practice-test/n4/grammar',
    name: 'N4GrammarTest',
    component: JLPTN4GRAMMARTESTCOMPONENT
  },
  {
    path: '/jlpt/study/n4/kanji',
    name: 'N4KanjiList',
    component: JLPTN4KANJILIST
  },
  {
    path: '/jlpt/practice-test/n3/kanji',
    name: 'N3KanjiTest',
    component: JLPTN3KANJITESTCOMPONENT
  },
  {
    path: '/jlpt/practice-test/n3/grammar',
    name: 'N3GrammarTest',
    component: JLPTN3GRAMMARTESTCOMPONENT
  },
  {
    path: '/jlpt/study/n3/kanji',
    name: 'N3KanjiList',
    component: JLPTN3KANJILIST
  },
  {
    path: '/jlpt/practice-test/n2/kanji',
    name: 'N2KanjiTest',
    component: JLPTN2KANJITESTCOMPONENT
  },
  {
    path: '/jlpt/practice-test/n2/grammar',
    name: 'N2GrammarTest',
    component: JLPTN2GRAMMARTESTCOMPONENT
  },
  {
    path: '/jlpt/study/n2/kanji',
    name: 'N2KanjiList',
    component: JLPTN2KANJILIST
  },
  {
    path: '/jlpt/practice-test/n1/kanji',
    name: 'N1KanjiTest',
    component: JLPTN1KANJITESTCOMPONENT
  },
  {
    path: '/jlpt/practice-test/n1/grammar',
    name: 'N1GrammarTest',
    component: JLPTN1GRAMMARTESTCOMPONENT
  },
  {
    path: '/jlpt/study/n1/kanji',
    name: 'N1KanjiList',
    component: JLPTN1KANJILIST
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;
