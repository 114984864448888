<template>
    <section class="block">
      <h2>Message from GyanMirai</h2>
      <p>Our passion for teaching Japanese stems from our own journey of learning the language and experiencing the culture. We believe that mastering Japanese can open up incredible opportunities, and we are dedicated to helping you achieve your language goals.</p>
    </section>
  </template>
  
  <script>
  export default {
    name: 'MessageFromGyanMiraiComponent'
  };
  </script>
  
  <style scoped>
  .block {
    background: white;
    padding: 30px;
    margin-bottom: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s, box-shadow 0.3s;
  }
  
  .block:hover {
    transform: translateY(-5px);
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  }
  
  h2 {
    font-size: 2em;
    margin-bottom: 20px;
    color: #333; /* Darker color for better contrast */
  }
  
  p {
    font-size: 1.2em;
    line-height: 1.6;
    margin-bottom: 20px;
    color: #666; /* Slightly lighter color for better readability */
  }
  </style>
  