<template>
  <div class="about">
    <NavbarComponent />
    <div class="container">
      <MessageFromGyanMiraiComponent />
      <AboutCompanyComponent />
      <!-- <TeamSectionComponent /> -->
    </div>
    <FooterComponent />
  </div>
</template>

<script>
import MessageFromGyanMiraiComponent from '@/components/about/MessageFromGyanMiraiComponent.vue';
import AboutCompanyComponent from '@/components/about/AboutCompanyComponent.vue';
// import TeamSectionComponent from '@/components/about/TeamSectionComponent.vue';

export default {
  name: 'AboutView',
  components: {
    MessageFromGyanMiraiComponent,
    AboutCompanyComponent,
    // TeamSectionComponent
  },
  metaInfo() {
    return {
      title: 'About Us | Gyanmirai',
      meta: [
        {
          name: 'description',
          content: 'Learn more about Gyanmirai, our mission, and the team behind our Japanese language courses. Discover our commitment to helping you succeed in Japanese language learning and JLPT preparation.'
        },
        {
          name: 'keywords',
          content: 'About Gyanmirai, Japanese language courses, Japanese learning, JLPT preparation, Gyanmirai team, company mission'
        },
        {
          name: 'robots',
          content: 'index, follow'
        },
        {
          property: 'og:title',
          content: 'About Us | Gyanmirai'
        },
        {
          property: 'og:description',
          content: 'Discover Gyanmirai’s mission, our dedicated team, and our commitment to excellence in Japanese language education. Learn how we help you prepare for the JLPT and succeed in your language journey.'
        },
        {
          property: 'og:url',
          content: 'https://www.gyanmirai.com/about'
        },
        {
          property: 'og:type',
          content: 'website'
        },
        {
          property: 'og:image',
          content: 'https://www.gyanmirai.com/images/banner.jpg'  // Replace with a relevant banner image
        }
      ],
      link: [
        { rel: 'canonical', href: 'https://www.gyanmirai.com/about' }
      ]
    }
  }
}
</script>

<style scoped>
body {
  margin: 0;
  font-family: Arial, Helvetica, sans-serif;
  padding-top: 90px;
}

.container {
  padding: 50px 20px;
  text-align: center;
  max-width: 1200px;
  margin: 0 auto;
}

.block {
  background: white;
  padding: 30px;
  margin-bottom: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
}

.block:hover {
  transform: translateY(-5px);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
}

h2 {
  font-size: 2em;
  margin-bottom: 20px;
  color: #333; /* Darker color for better contrast */
}

p {
  font-size: 1.2em;
  line-height: 1.6;
  margin-bottom: 20px;
  color: #666; /* Slightly lighter color for better readability */
}
</style>
