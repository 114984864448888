export const structuredData = {
    "@context": "https://schema.org",
    "@type": "EducationalOrganization",
    "name": "Gyanmirai",
    "url": "https://www.gyanmirai.com/",
    "description": "Gyanmirai offers comprehensive Japanese language classes, JLPT and NAT mock tests for kanji, vocabulary, grammar, listening, and reading. We provide expert career guidance to help students secure jobs in Japan, including personalized job hunting support, resume creation, and interview preparation.",
    "logo": "https://www.gyanmirai.com/wp-content/uploads/2023/06/cropped-cropped-Gyanmirai-Logo-1.png",
    "sameAs": [
      "https://www.facebook.com/gyanmirai",
      "https://www.instagram.com/gyanmirai_official/"
    ],
    "address": {
      "@type": "PostalAddress",
      "addressCountry": "Japan"
    },
    "contactPoint": {
      "@type": "ContactPoint",
      "contactType": "customer support",
      "email": "info@gyanmirai.com"
    },
    "offers": [
      {
        "@type": "Offer",
        "name": "Japanese Language Classes",
        "description": "Comprehensive Japanese language courses for all levels"
      },
      {
        "@type": "Offer",
        "name": "JLPT Preparation",
        "description": "Specialized courses and mock tests for JLPT (Japanese Language Proficiency Test) preparation"
      },
      {
        "@type": "Offer",
        "name": "NAT Test Preparation",
        "description": "Focused training and mock tests for NAT (Nihongo Noryoku Test) preparation"
      },
      {
        "@type": "Offer",
        "name": "Mock Tests",
        "description": "Full-length mock tests for kanji, vocabulary, grammar, listening, and reading skills"
      },
      {
        "@type": "Offer",
        "name": "Career Guidance",
        "description": "Expert career counseling and job hunting support for the Japanese job market"
      }
    ],
    "keywords": "Japanese language, JLPT preparation, NAT test, mock tests, kanji, vocabulary, grammar, listening, reading, career guidance, job hunting in Japan"
  };