<template>
  <section class="home-banner">
    <div class="banner-content">
      <div class="banner-text">
        <h1>Master Japanese with Us!</h1>
        <p>Achieve JLPT N5, N4, and N3 Proficiency and Unlock Job Opportunities in Japan</p>
        <router-link to="/about" class="btn btn-primary">Learn More</router-link>
      </div>
      <div class="banner-images">
        <img
          v-for="(image, index) in bannerImages"
          :src="image"
          :key="index"
          :class="{ active: index === currentBannerIndex }"
          class="banner-image"
        />
        <div class="banner-dots">
          <span
            v-for="(image, index) in bannerImages"
            :key="index"
            :class="{ active: index === currentBannerIndex }"
            @click="currentBannerIndex = index"
          ></span>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'BannerComponent',
  data() {
    return {
      bannerImages: [
        require('@/assets/banner/banner-image1.jpg'),
        require('@/assets/banner/banner-image2.jpg'),
        require('@/assets/banner/banner-image3.jpg'),
        require('@/assets/banner/banner-image4.jpg'),
        require('@/assets/banner/banner-image5.jpg')
      ],
      currentBannerIndex: 0
    };
  },
  mounted() {
    this.startBannerRotation();
  },
  methods: {
    startBannerRotation() {
      setInterval(() => {
        this.currentBannerIndex = (this.currentBannerIndex + 1) % this.bannerImages.length;
      }, 5000);
    }
  }
};
</script>

<style scoped>
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css');

.home-banner {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  background: linear-gradient(to right, #6a11cb, #2575fc);
  color: white;
  border-radius: 15px;
  margin: 20px;
}

.banner-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  flex-direction: row;
}

.banner-text {
  flex: 1;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  animation: fadeInLeft 1s ease-in-out;
}

.banner-text h1 {
  font-size: 2.5em;
  margin: 0;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: bold;
}

.banner-text p {
  font-size: 1.2em;
  margin: 20px 0;
  text-align: center;
}

.banner-text .btn {
  background: #ff6347;
  color: white;
  padding: 10px 20px;
  text-decoration: none;
  font-size: 1.2em;
  border-radius: 5px;
  transition: background 0.3s;
}

.banner-text .btn:hover {
  background: #e55347;
}

.banner-images {
  flex: 1;
  position: relative;
  height: 480px;
  overflow: hidden;
  border-radius: 10px;
  margin-left: 20px;
  animation: fadeInRight 1s ease-in-out;
}

.banner-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  transition: opacity 1s ease-in-out;
}

.banner-image.active {
  opacity: 1;
}

.banner-dots {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 5px;
}

.banner-dots span {
  width: 10px;
  height: 10px;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  cursor: pointer;
  transition: background 0.3s;
}

.banner-dots span.active {
  background: rgba(255, 255, 255, 1);
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translateX(-50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translateX(50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

/* Media query for smaller screens */
@media (max-width: 768px) {
  .banner-content {
    flex-direction: column;
    align-items: center;
  }

  .banner-images {
    margin-left: 0;
    margin-top: 20px;
    height: 300px;
  }

  .banner-text h1 {
    font-size: 2em;
  }

  .banner-text p {
    font-size: 1em;
  }

  .banner-text .btn {
    font-size: 1em;
  }
}
</style>
