import { ref, computed } from 'vue';

export function useKanjiLogic() {
  const kanjiList = ref([]);
  const filteredKanjiList = ref([]);
  const searchQuery = ref('');
  const activeKanjiIndex = ref(null);

  const fetchKanjiData = async (jsonFile) => {
    try {
      const response = await fetch(jsonFile);
      const data = await response.json();
      kanjiList.value = data.map(item => ({
        ...item,
        showDetails: false,
        learned: false,
      }));
      filteredKanjiList.value = kanjiList.value;
    } catch (error) {
      console.error("Error fetching kanji data:", error);
    }
  };

  // Parses the examples and handles both [] and () for reading
  const parseExamples = (examples) => {
    return examples.split("\n").map((example) => {
      return {
        japanese: extractJapanese(example),
        reading: extractReading(example),
        translation: extractTranslation(example)
      };
    });
  };

  const extractJapanese = (example) => {
    const match = example.match(/\[|\(/);
    if (match) {
      return example.split(match[0])[0];
    }
    return example; // If no match, return the whole example
  };

  const extractReading = (example) => {
    const match = example.match(/\[|\(/); // Match [ or (
    if (match) {
      const closingBracket = match[0] === '[' ? ']' : ')'; // Determine closing bracket
      return example.split(match[0])[1].split(closingBracket)[0]; // Extract reading
    }
    return ''; // No reading found
  };

  const extractTranslation = (example) => {
    const match = example.match(/\] |\) /); // Match ] or ) followed by a space
    if (match) {
      return example.split(match[0])[1]; // Extract translation after the reading
    }
    return ''; // No translation found
  };

  const filterKanji = () => {
    const query = searchQuery.value.toLowerCase();
    filteredKanjiList.value = kanjiList.value.filter(kanji =>
      kanji.kanji.toLowerCase().includes(query) ||
      kanji.meaning.toLowerCase().includes(query) ||
      kanji['on-yomi'].toLowerCase().includes(query) ||
      kanji['kun-yomi'].toLowerCase().includes(query)
    );
  };

  const markAsLearned = (index) => {
    filteredKanjiList.value[index].learned = !filteredKanjiList.value[index].learned;
  };

  const openKanjiDetails = (index) => {
    activeKanjiIndex.value = index;
  };

  const closeKanjiDetails = () => {
    activeKanjiIndex.value = null;
  };

  const navigateKanji = (step) => {
    const newIndex = activeKanjiIndex.value + step;
    if (newIndex >= 0 && newIndex < filteredKanjiList.value.length) {
      activeKanjiIndex.value = newIndex;
    }
  };

  return {
    kanjiList,
    filteredKanjiList,
    searchQuery,
    activeKanjiIndex,
    fetchKanjiData,
    parseExamples,
    filterKanji,
    markAsLearned,
    openKanjiDetails,
    closeKanjiDetails,
    navigateKanji
  };
}