<template>
  <div class="inquiry-form-container">
    <h2>Contact Us</h2>
    <form @submit.prevent="submitInquiry" class="inquiry-form">
      <div class="form-group" :class="{ 'has-error': errors.name }">
        <label for="name">Name</label>
        <input type="text" id="name" v-model="name" required class="form-control">
        <span v-if="errors.name" class="error-message">{{ errors.name }}</span>
      </div>
      <div class="form-group" :class="{ 'has-error': errors.email }">
        <label for="email">Email</label>
        <input type="email" id="email" v-model="email" required class="form-control">
        <span v-if="errors.email" class="error-message">{{ errors.email }}</span>
      </div>
      <div class="form-group" :class="{ 'has-error': errors.phone }">
        <label for="phone">Phone Number</label>
        <input type="tel" id="phone" v-model="phone" required class="form-control">
        <span v-if="errors.phone" class="error-message">{{ errors.phone }}</span>
      </div>
      <div class="form-group" :class="{ 'has-error': errors.course }">
        <label for="course">Course Interest</label>
        <select id="course" v-model="course" class="form-control">
          <option value="">Select a course</option>
          <option value="N5">JLPT N5</option>
          <option value="N4">JLPT N4</option>
          <option value="N3">JLPT N3</option>
        </select>
        <span v-if="errors.course" class="error-message">{{ errors.course }}</span>
      </div>
      <div class="form-group" :class="{ 'has-error': errors.message }">
        <label for="message">Message/Inquiry</label>
        <textarea id="message" v-model="message" rows="4" class="form-control"></textarea>
        <span v-if="errors.message" class="error-message">{{ errors.message }}</span>
      </div>
      <button type="submit" class="btn btn-primary">Submit Inquiry</button>
    </form>
    <transition name="fade">
      <div v-if="notification.visible" :class="['notification', notification.type]">
        {{ notification.message }}
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'InquiryFormComponent',
  data() {
    return {
      name: '',
      email: '',
      phone: '',
      course: '',
      message: '',
      errors: {},
      notification: {
        visible: false,
        message: '',
        type: ''
      }
    };
  },
  methods: {
    async submitInquiry() {
      this.errors = {};
      if (!this.validateForm()) {
        return;
      }
      const formData = {
        name: this.name,
        email: this.email,
        phone: this.phone,
        course: this.course,
        message: this.message
      };

      try {
        const response = await fetch('https://www.gyanmirai.com/api/submit-inquiry', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(formData)
        });

        if (response.ok) {
          this.showNotification('Inquiry submitted successfully!', 'success');
          // Reset the form fields
          this.name = '';
          this.email = '';
          this.phone = '';
          this.course = '';
          this.message = '';
        } else {
          this.showNotification('Failed to submit inquiry. Please try again.', 'error');
        }
      } catch (error) {
        console.error('Error submitting inquiry:', error);
        this.showNotification('An error occurred. Please try again.', 'error');
      }
    },
    validateForm() {
      let valid = true;
      if (!this.name) {
        this.errors.name = 'Name is required.';
        valid = false;
      }
      if (!this.email) {
        this.errors.email = 'Email is required.';
        valid = false;
      } else if (!this.validEmail(this.email)) {
        this.errors.email = 'Valid email is required.';
        valid = false;
      }
      if (!this.phone) {
        this.errors.phone = 'Phone number is required.';
        valid = false;
      }
      if (!this.course) {
        this.errors.course = 'Course selection is required.';
        valid = false;
      }
      if (!this.message) {
        this.errors.message = 'Message is required.';
        valid = false;
      }
      return valid;
    },
    validEmail(email) {
      const re = /^(([^<>()[\\.,;:\s@"]+(\.[^<>()[\\.,;:\s@"]+)*)|(".+"))@(([^<>()[\\.,;:\s@"]+\.)+[^<>()[\\.,;:\s@"]{2,})$/i;
      return re.test(email);
    },
    showNotification(message, type) {
      this.notification.message = message;
      this.notification.type = type;
      this.notification.visible = true;
      setTimeout(() => {
        this.notification.visible = false;
      }, 3000);
    }
  }
};
</script>

<style scoped>
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css');

.inquiry-form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #f7f9fc;
  padding: 30px;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.inquiry-form-container h2 {
  font-size: 2.5em;
  margin-bottom: 30px;
  color: #007BFF;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
}

.inquiry-form {
  display: grid;
  gap: 15px;
  max-width: 500px;
  width: 100%;
  justify-items: center;
}

.inquiry-form .form-group {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.inquiry-form .form-group label {
  align-self: flex-start;
}

.inquiry-form .form-group input,
.inquiry-form .form-group select,
.inquiry-form .form-group textarea {
  width: 100%;
}

.inquiry-form .form-group.has-error input,
.inquiry-form .form-group.has-error select,
.inquiry-form .form-group.has-error textarea {
  border-color: #e74c3c;
}

.inquiry-form .form-group.has-error .error-message {
  color: #e74c3c;
  font-size: 0.875em;
  margin-top: 5px;
}

.inquiry-form label {
  font-size: 1.2em;
  display: block;
  margin-bottom: 5px;
}

.inquiry-form input,
.inquiry-form select,
.inquiry-form textarea,
.inquiry-form button {
  padding: 10px;
  font-size: 1em;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
}

.inquiry-form button {
  background: #ff6347;
  color: white;
  border: none;
  cursor: pointer;
  font-size: 1.2em;
  transition: background 0.3s;
}

.inquiry-form button:hover {
  background: #e55347;
}

/* Notification styles */
.notification {
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  padding: 15px 30px;
  border-radius: 5px;
  color: white;
  font-size: 1.2em;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.notification.success {
  background-color: #28a745;
}

.notification.error {
  background-color: #dc3545;
}

/* Fade transition */
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  .inquiry-form-container {
    padding: 20px;
    border-radius: 10px;
    max-width: 100%;
    width: 100%;
    box-sizing: border-box;
  }

  .inquiry-form-container h2 {
    font-size: 2em;
    margin-bottom: 20px;
  }

  .inquiry-form {
    display: grid;
    gap: 15px;
    max-width: 100%;
    width: 100%;
    justify-items: center;
    padding-left: 20px; /* Add padding for mobile left */
    padding-right: 20px; /* Add padding for mobile right */
    box-sizing: border-box;
  }

  .inquiry-form .form-group {
    margin-bottom: 15px;
    width: 100%;
    padding-left: 10px;  /* Extra space for each input field */
    padding-right: 10px; /* Extra space for each input field */
    box-sizing: border-box;
  }

  .inquiry-form .form-group input,
  .inquiry-form .form-group select,
  .inquiry-form .form-group textarea {
    font-size: 0.9em;
    padding: 8px;
    width: 100%;
    box-sizing: border-box;
  }

  .inquiry-form button {
    font-size: 1em;
    padding: 10px;
    width: 100%;
  }

  .notification {
    font-size: 1em;
    padding: 10px 20px;
    width: 90%;
  }
}
</style>
