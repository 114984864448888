<template>
    <div class="test-view">
      <h1>{{ testTitle }}</h1>
  
      <!-- Start Screen Section -->
      <div v-if="!testStarted" class="start-screen">
        <label for="questionCount" class="label">Number of questions:</label>
        <input 
          type="number" 
          id="questionCount" 
          v-model.number="questionCount" 
          min="1" 
          :max="maxQuestions" 
          class="input"
          placeholder="Enter number of questions"
        >
        <div class="button-container">
          <button 
            @click="startTest" 
            :disabled="!questionCount" 
            class="start-button"
          >
            Start Test
          </button>
        </div>
      </div>
  
      <!-- Test Section -->
      <div v-else-if="!testCompleted" class="test-container">
        <div class="header">
          <div class="timer">Time: {{ formatTime(timeElapsed) }}</div>
          <div class="progress">Question {{ currentQuestionIndex + 1 }} of {{ selectedQuestions.length }}</div>
        </div>
  
        <div class="question-navigator">
          <button 
            v-for="(question, index) in selectedQuestions" 
            :key="index"
            @click="goToQuestion(index)"
            :class="{ 'answered': userAnswers[index], 'current': currentQuestionIndex === index }"
          >
            {{ index + 1 }}
          </button>
        </div>
  
        <div class="question" v-if="currentQuestion">
          <h2 v-html="formatQuestion(currentQuestion.question)"></h2>
          <ul class="options">
            <li v-for="(option, index) in currentQuestion.options" :key="index">
              <button 
                @click="selectAnswer(option)" 
                :class="{ selected: userAnswers[currentQuestionIndex] === option }"
              >
                {{ option }}
              </button>
            </li>
          </ul>
        </div>
  
        <div class="navigation">
          <button @click="previousQuestion" :disabled="currentQuestionIndex === 0">Previous</button>
          <button @click="finishTest" class="submit-button">Submit</button>
          <button @click="nextQuestion" :disabled="currentQuestionIndex === selectedQuestions.length - 1">Next</button>
        </div>
      </div>
  
      <!-- Results Section -->
      <div v-else class="results">
        <h2>Test Results</h2>
        <div class="score">Your score: {{ score }} / {{ selectedQuestions.length }}</div>
        <div class="result-details">
          <div v-for="(question, index) in selectedQuestions" :key="index" class="result-item">
            <h3>Question {{ index + 1 }}</h3>
            <p v-html="formatQuestion(question.question)"></p>
            <p>Your answer: 
              <span :class="{ correct: userAnswers[index] === question.correct_answer, incorrect: userAnswers[index] !== question.correct_answer }">
                {{ userAnswers[index] || 'Not answered' }}
              </span>
            </p>
            <p>Correct answer: <span class="correct">{{ question.correct_answer }}</span></p>
          </div>
        </div>
        <button @click="resetTest" class="restart-button">Take Another Test</button>
      </div>
    </div>
  </template>
  
  <script>
  import { onMounted, onUnmounted } from 'vue';
  import { useTestLogic } from './testLogic';
  
  export default {
    name: 'TestComponent',
    props: {
      testTitle: {
        type: String,
        required: true
      },
      jsonFile: {
        type: String,
        required: true
      }
    },
    setup(props) {
      const testLogic = useTestLogic();
  
      onMounted(() => {
        testLogic.loadQuestions(props.jsonFile);
      });
  
      onUnmounted(() => {
        if (testLogic.stopTimer) {
            testLogic.stopTimer();
        }
      });
  
      return {
        ...testLogic
      };
    }
  };
  </script>
  
  <style scoped>
  .test-view {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
  h1 {
    font-size: 2.5rem;
    color: #333;
    text-align: center;
    margin: 20px 0;
    font-weight: bold;
    letter-spacing: 1.5px;
    line-height: 1.2;
    background-color: #f0f0f0;
    padding: 15px 30px;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .start-screen {
    text-align: center;
    margin-top: 50px;
  }
  
  .label {
    display: block;
    font-size: 1.2rem;
    margin-bottom: 10px;
  }
  
  .input {
    font-size: 1.2rem;
    padding: 10px;
    width: 100%;
    max-width: 300px;
    margin: 10px 0;
    border-radius: 5px;
    border: 1px solid #ccc;
  }
  
  .button-container {
    margin-top: 20px;
  }
  
  .start-button {
    background-color: #4CAF50;
    color: white;
    padding: 12px 24px;
    font-size: 18px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .start-button:hover {
    background-color: #45a049;
  }
  
  .start-button:disabled {
    background-color: #ddd;
    cursor: not-allowed;
  }
  
  .header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  
  .timer, .progress {
    font-size: 18px;
    margin-bottom: 10px;
  }
  
  .test-container {
    background-color: #f0f0f0;
    border-radius: 8px;
    padding: 20px;
    margin-top: 20px;
  }
  
  .options {
    list-style-type: none;
    padding: 0;
  }
  
  .options li {
    margin-bottom: 10px;
  }
  
  .options button {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    background-color: #fff;
    border: 2px solid #ddd;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .options button:hover:not(:disabled) {
    background-color: #e6e6e6;
  }
  
  .options button.selected {
    background-color: #4CAF50;
    color: white;
  }
  
  .navigation {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  
  .navigation button {
    padding: 10px 20px;
    font-size: 16px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .navigation button:disabled {
    background-color: #ddd;
    cursor: not-allowed;
  }
  
  .submit-button {
    background-color: #f44336;
  }
  
  .results {
    text-align: center;
  }

  .question h2 {
    font-size: 24px;
    margin-bottom: 20px;
    font-weight: normal; /* Ensure the entire question is not bold by default */
  }
  
  .bold {
      font-weight: bold;
  }
  
  .score {
    font-size: 24px;
    font-weight: bold;
    margin: 20px 0;
  }
  
  .result-details {
    margin-top: 20px;
    text-align: left;
  }
  
  .result-item {
    background-color: #f0f0f0;
    border-radius: 8px;
    padding: 15px;
    margin-bottom: 15px;
  }
  
  .correct {
    color: #4CAF50;
    font-weight: bold;
  }
  
  .incorrect {
    color: #f44336;
    font-weight: bold;
  }
  
  .question-navigator {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    margin-bottom: 20px;
  }
  
  .question-navigator button {
    width: 30px;
    height: 30px;
    border: 1px solid #ddd;
    background-color: #fff;
    cursor: pointer;
  }
  
  .question-navigator button.answered {
    background-color: #4CAF50;
    color: white;
  }
  
  .question-navigator button.current {
    border: 2px solid #000;
  }
  
  @media (max-width: 728px) {
    h1 {
      font-size: 2rem;
      padding: 10px 20px;
    }
  
    .input {
      font-size: 1rem;
    }
  
    .options button {
      font-size: 14px;
    }
  
    .navigation button {
      padding: 8px 16px;
      font-size: 14px;
    }
  }
  </style>
  