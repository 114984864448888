<template>
  <div id="app">
    <NavbarComponent />
    <div class="content">
      <router-view />
    </div>
    <FooterComponent />
  </div>
</template>

<script>
import NavbarComponent from '@/components/NavbarComponent.vue'
import FooterComponent from '@/components/FooterComponent.vue'
import { structuredData } from '@/data/structuredData.js';

export default {
  components: {
    NavbarComponent,
    FooterComponent
  },
  mounted() {
    this.addStructuredData();
  },
  methods: {
    addStructuredData() {
      const script = document.createElement('script');
      script.type = 'application/ld+json';
      script.innerHTML = JSON.stringify(structuredData);
      document.head.appendChild(script);
    }
  }
}
</script>

<style>
html, body, #app {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: 'Arial', sans-serif;
  background-color: #f4f4f4;
  color: #333;
}

#app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content {
  flex: 1;
}

nav {
  background: #333;
  color: #fff;
  padding: 1rem;
  text-align: center;
}

nav ul {
  list-style: none;
  padding: 0;
}

nav ul li {
  display: inline;
  margin: 0 10px;
}

nav ul li a {
  color: #fff;
  text-decoration: none;
  font-size: 1.2em;
}

nav ul li a:hover {
  text-decoration: underline;
}

footer {
  background: #333;
  color: #fff;
  text-align: center;
  padding: 1rem 0;
  width: 100%;
}
</style>
