<template>
  <footer class="footer">
    <div class="container">
      <p>© 2024 GyanMirai. All rights reserved.</p>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'FooterComponent'
}
</script>

<style scoped>
.footer {
  text-align: center;
  padding: 40px, 0; /* Padding for larger screens */
  background: linear-gradient(to right, #6a11cb, #2575fc); /* Gradient background */
  color: white; /* Light text color */
  border-top: 1px solid #e7e7e7;
  margin-top: 10px;
  height: auto; /* Ensure height grows with content */
}

@media (max-width: 768px) {
  .footer {
    padding: 20px, 0; /* Reduced padding for smaller screens */
  }
}
</style>
