<template>
  <div class="mission-block">
    <h2>Our Mission</h2>
    <div class="mission-content">
      <div class="mission-image-container">
        <img :src="currentMission.image" alt="Mission Image" class="mission-image" />
      </div>
      <div class="mission-text">
        <ul>
          <li v-for="(item, index) in currentMission.items" :key="index">
            <div class="text-header">
              <i :class="item.icon"></i> 
              <span class="bold">{{ item.title }}</span>
            </div>
            <p>{{ item.description }}</p>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MissionComponent',
  data() {
    return {
      missions: [
        {
          image: require('@/assets/mission/quality_education.jpg'),
          items: [
            { icon: 'fas fa-chalkboard-teacher', title: 'Quality Education', description: 'Delivering high-quality Japanese language education with experienced and passionate instructors.' }
          ]
        },
        {
          image: require('@/assets/mission/simple_career_support.jpg'),
          items: [
            { icon: 'fas fa-briefcase', title: 'Career Support', description: 'Offering personalized career guidance to help students navigate the job market in Japan.' }
          ]
        },
        {
          image: require('@/assets/mission/cultural_understanding_japan.jpg'),
          items: [
            { icon: 'fas fa-globe', title: 'Cultural Understanding', description: 'Promoting cultural awareness and understanding to help students integrate seamlessly into Japanese society.' }
          ]
        },
        {
          image: require('@/assets/mission/community_building_students.jpg'),
          items: [
            { icon: 'fas fa-users', title: 'Community Building', description: 'Fostering a sense of community among our students, encouraging collaboration and mutual support.' }
          ]
        },
        {
          image: require('@/assets/mission/lifelong_learning.jpg'),
          items: [
            { icon: 'fas fa-graduation-cap', title: 'Lifelong Learning', description: 'Encouraging continuous learning and personal growth, beyond just language acquisition.' }
          ]
        }
      ],
      currentMissionIndex: 0
    };
  },
  computed: {
    currentMission() {
      return this.missions[this.currentMissionIndex];
    }
  },
  mounted() {
    setInterval(() => {
      this.currentMissionIndex = (this.currentMissionIndex + 1) % this.missions.length;
    }, 5000);
  }
};
</script>

<style scoped>
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css');

.mission-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #f7f9fc;
  padding: 10px 30px;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  margin: 20px;
}

.mission-block h2 {
  font-size: 3em;
  margin-bottom: 20px;
  color: #007BFF;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
}

.mission-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
}

.mission-image-container {
  flex: 1;
  position: relative;
  height: 500px; /* Adjusted height to fit content */
  overflow: hidden;
  border-radius: 10px;
  margin-right: 20px;
  padding-bottom: 40px;
}

.mission-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.mission-text {
  flex: 1;
  text-align: left;
}

.mission-text ul {
  list-style-type: none;
  padding: 0;
}

.mission-text li {
  margin: 20px 0;
  font-size: 1.5em;
  color: #555;
}

.text-header {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}

.text-header i {
  margin-right: 10px;
  color: #007BFF;
  font-size: 1.5em;
}

.bold {
  font-weight: bold;
  text-align: center;
  display: inline-block;
}

@media (max-width: 768px) {
  .mission-block {
    padding: 30px 20px;
  }

  .mission-block h2 {
    font-size: 2em;
  }

  .mission-content {
    flex-direction: column;
  }

  .mission-image-container {
    height: 250px;
    margin-right: 0;
    margin-bottom: 20px;
    width: 100%;
  }

  .mission-text {
    width: 100%;
  }

  .mission-text li {
    font-size: 1em;
    margin: 15px 0;
  }

  .text-header {
    justify-content: flex-start;
  }

  .text-header i {
    font-size: 1.1em;
  }
}
</style>
