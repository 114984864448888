<template>
  <TestComponent 
    testTitle="JLPT N1 Grammar Test" 
    jsonFile="/jlpt/n1/grammar/jlpt_n1_grammar_questions.json"
  />
</template>

<script>
import TestComponent from '@/components/jlpt/TestComponent.vue';

export default {
  name: 'JLPTN1GRAMMARTESTCOMPONENT',
  components: {
    TestComponent
  },
  metaInfo() {
    return {
      title: 'JLPT N1 Grammar Practice Test | Gyanmirai',
      meta: [
        {
          name: 'description',
          content: 'Take the JLPT N1 Grammar practice test on Gyanmirai. Test your understanding of essential N1 grammar points and prepare for the JLPT exam with our comprehensive mock tests.'
        },
        {
          name: 'keywords',
          content: 'JLPT N1, Grammar test, JLPT N1 grammar, JLPT mock tests, Japanese grammar, N1 exam preparation, Gyanmirai'
        },
        {
          name: 'robots',
          content: 'index, follow'
        },
        {
          property: 'og:title',
          content: 'JLPT N1 Grammar Practice Test | Gyanmirai'
        },
        {
          property: 'og:description',
          content: 'Prepare for the JLPT N1 exam by practicing grammar with Gyanmirai’s free mock tests. Test your N1 grammar knowledge and improve your chances of success in the exam.'
        },
        {
          property: 'og:url',
          content: 'https://www.gyanmirai.com/jlpt/practice-test/n1/grammar'
        },
        {
          property: 'og:type',
          content: 'website'
        },
        {
          property: 'og:image',
          content: 'https://www.gyanmirai.com/images/banner.jpg'  // Replace with a relevant banner image for grammar
        }
      ],
      link: [
        { rel: 'canonical', href: 'https://www.gyanmirai.com/jlpt/practice-test/n1/grammar' }
      ]
    }
  }
}
</script>
